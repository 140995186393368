.search {
	margin: auto;
	width: auto;
	padding: 10px;
}

.part_div {
	border-style: solid;
	padding: 1%;
}

.block_size {
	min-height: 250px !important;
	display: flex;
	flex-wrap: wrap;
}

.row_size_under_block {
	height: 100% !important;
}

.img_select_search {
	width: 30px !important;
	height: 30px !important;
	object-fit: cover !important;
}

.one_two_images {
	height: 100% !important;
}

.part_label {
	padding-left: 11px;
}

.top_menu {
	background-color: #0C687F;
	max-height: 75px;
	padding-top: 16px;
	z-index: 999;
	height: 76px;
}

.main_panel_modif {
	width: 100%;
	float: left !important;
}

.select_top {
	float: left;
	color: #fff;
	cursor: pointer;
	height: 100%;
	margin: 0;
	background-color: #0C687F;
	border: 0;
}

.deconnection_top {
	float: right;
}

.image_list_block {
	height: 320px;
}

#projList {
	margin-top: 30px;

}

#projList h2 {
	font-size: 14px !important;
	color: #000000 !important;

}

#projList a img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.right_container {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
}

.lang_select {
	background-color: #0C687F;
	border: 0;
	width: 100%;
	padding-top: 7%;
}

.img_projects_all_div {
	width: 100%;
	height: 100%;
}

select {
	background-color: #0C687F;
	border: 0;
	width: 100%;
}

#details_project .one_image_block {
	padding: 0px !important;
}

.unset_image_height {
	height: 50% !important;
}

.image_greater_two {
	height: 100% !important;
}

.navi {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.img_block {
	position: relative;
}

.infoi {
	position: absolute;
	background-color: rgb(0 0 0 / 30%);
	color: #f5f5f5;
	left: 0;
	top: 0;
	height: Calc(100% - 10px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	cursor: pointer;
}

.infoi p {
	font-size: 50px;
	font-weight: bold;
}

.part_images {
	width: unset;
}

.images_parts_details {
	padding: 0px;
	height: 100%;
}

.title_parts_details {
	color: #000000 !important;
	font-weight: bold !important;
	font-size: 48px;
	margin-top: 10px;
}

.row_one_image_detail {
	display: flex;
	flex-direction: column-reverse;
}

.part_details_block {
	border: 2px solid #5a9bd5;
}

.img_part_details {
	object-fit: cover;
	border-radius: 10px;
}

.projet_description_details {
	font-size: 24px;
	font-weight: 400;
}

.details {
	padding: 2% 6%;
}

.details .title {
	color: #000;
	margin-top: 15px;
	display: inline-block;
	font-family: Barlow Condensed, sans-serif;
	font-size: 24px;
	font-weight: 700;
	padding-bottom: 10px;
	padding-top: 10px;

}

.date_creation_details {
	position: absolute;
	top: 30px;
	right: 30px;
	background-color: #000;
	border: 1px solid #000;
	border-radius: 15px;
	color: white;
	display: inline-block;
	font-family: Barlow Condensed, sans-serif;
	font-size: 14px;
	font-weight: 700;
	list-style-type: none;
	margin-bottom: 5px;
	height: 38px;
	padding: 8px 16px;
	text-align: center;
}

.calque_img {
	background: linear-gradient(90deg, rgba(2, 0, 36, 0.8953956582633054) 0%, rgba(2, 18, 22, 0.18110994397759106) 41%);
	background-size: cover;
	width: 100%;
	height: 100%;
	color: white;
	padding: 20px;
	position: absolute;
	border-radius: 10px;
}

.titre_detai_in_img {
	position: absolute;
	max-width: calc(100% - 92px);
	top: 84px;
	left: 92px;

}

.titre_detai_in_img h1 {
	color: white;
	font-size: 70px;
	font-family: Barlow Condensed, sans-serif;
	font-weight: 700;
}

.categories_details {
	float: left;
	margin: 16px;
	padding: 0px;
	width: 100%;
}

.cover_image_details {
	position: relative;
	background-image: url("");
	background-repeat: no-repeat;
	height: 426px !important;
	border-radius: 10px;
	background-size: cover;
	background-position: center;
}

.name_categorie_details,
.url_projet {
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 15px;
	color: #000;
	display: inline-block;
	font-family: Barlow Condensed, sans-serif;
	font-size: 14px;
	font-weight: 700;
	list-style-type: none;
	margin-bottom: 5px;
	height: 38px;
	padding: 8px 16px;
	text-align: center;
	box-shadow: 0px 0px 5px #0000001a;
	margin-right: 10px;
}

.url_projet {
	position: absolute;
	bottom: 30px;
	right: 30px;
	padding-right: 44px !important;

	background-image: url(https://portfolio.3wwit.com/Projects/internet_icon.svg);
	background-repeat: no-repeat;
	background-position: Calc(100% - 16px) 11px;
	background-size: 18px 18px;
}

.url_projet a {
	font-family: Barlow Condensed, sans-serif;
	font-size: 14px;
	font-weight: 700;
	color: black;
}

.description_details {
	text-align: center;
	font-size: 24px;
	font-family: Barlow Condensed, sans-serif;
	font-weight: normal;
	color: black;
}

#select2_image_text {
	display: none;
}

.filer_modal {

	background: #fff;
	box-sizing: border-box;
	height: 100%;
	margin: unset;
	max-width: 500px;
	position: relative;
	vertical-align: middle;
	width: 250px;
	z-index: 2;
	max-height: 100%;
	box-shadow: 5px 7px 11px 6px #0b0c0c45;
	font-family: 'Barlow Condensed', sans-serif;
}

.filtre_bloc {
	background-color: #fff;
	padding-left: 14px;
	padding-right: 14px;
	top: 0;
	position: absolute;
	border-bottom: 1px solid #e9e9e9 !important;
	margin: 0;
	width: 100%;
	z-index: 3;
	height: 75px;

}

.modalmsg {
	text-align: start;
	display: block;
	font-size: 20px;
	font-weight: 700;
	margin-top: 17px;
}


.btn_valide_model {
	background-color: #32aa6e;
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	position: absolute;
	bottom: 0;
	margin: 0;
	padding-top: 15px;
	width: 100%;
	height: 50px;
}

.block_Category {
	border-top: 2px solid #f6f6f6;
	border-spacing: 5px;
	overflow-y: scroll;
	height: calc(95% - 25px);
	margin-top: 70px;
	padding-left: 14px;
	padding-right: 14px;
	padding-bottom: 70px;
	color: black;
}

.index_title_page {
	font-family: Barlow Condensed, sans-serif;
	font-size: 32px;
	font-weight: bold;
}

.categories_liste_name {
	padding: 0;
	width: 100%;
}

details .card_details {
	border-bottom: 0 !important;
}

.name_categorie_projet {
	background-color: #fff;
	border-radius: 10px;
	color: #000;
	display: inline-block;
	font-family: Barlow Condensed, sans-serif;
	font-size: 12px;
	font-weight: 700;
	list-style-type: none;
	margin: 2px;
	padding: 5px 10px;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
}

.all_cat {
	font-size: 16px;
}

.block_Category::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #d5d5d5;
	border-radius: 10px;
}

.block_Category::-webkit-scrollbar-thumb {
	background: #848484;
	border-radius: 10px;
}

.sous_categorie_name {
	margin-bottom: 10px;
}

.sous_categorie_name span {
	font-size: 16px;
}

.btn_valide_model:hover {
	cursor: pointer;
}

.filter_moda_added_div {
	background-color: unset !important;
	justify-content: flex-end !important;
}

.child_of_cat {
	border-bottom: 1.5px solid #e9e9e9 !important;
	margin-bottom: 10px;
}

.filter_moda_added_div {
	background-color: unset !important;
	justify-content: flex-end !important;
}

.div_close {
	display: inline;
	top: 11px;
	left: 56%;
	position: relative;
}

.close_modal_filter {
	cursor: pointer;
	position: absolute;
	left: 100%;
	top: -20px;

}

.description_part_projet {
	word-wrap: break-word !important;
	padding-right: 44px;
}

.div_close:hover {
	cursor: pointer;
	color: black;

}

.logo_categorie {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	vertical-align: sub;
}

.block_Category .categorie_name {
	margin-bottom: 10px;
}

.block_Category .categorie_libelle .bn_total_categorie {
	position: absolute;
	right: 20px;
	color: black;
	font-size: 18px;
	font-weight: normal;

}

.block_Category .categorie_libelle {
	margin-bottom: 10px;
	font-size: 18px;
}

.categorie_libelle label {
	font-size: 18px;
	font-weight: bold;
}

.input_tags {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.categorie_child_tags {
	float: left;
	padding: 6px;
	border: 1px solid;
	margin-bottom: 10px;
	margin-right: 10px;
	border-radius: 5px;
	position: relative;
	text-align: center;
}

.close_tag {
	position: absolute;
	right: -8px;
	top: -9px;
	cursor: pointer;
}

.search_button {
	color: black;
	border: 1px solid black;
	font-size: 18px;
	font-family: 'Barlow Condensed', sans-serif;
	font-weight: bold;
	background-image: url(https://portfolio.3wwit.com/Projects/filtre-icon.svg);
	background-position: 85% 50%;
	background-repeat: no-repeat;
	min-width: 100px;
	text-align: start;
}

.clo_filtre_btn {
	float: right;
	width: 110px;
}

.bloc_input_tags {
	width: calc(100% - 110px);
}

.cover_projet {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-top-left-radius: 15px !important;
	border-top-right-radius: 15px !important;
}

.crad_projet {
	width: 260px !important;
}

.crad_projet .card {
	border-radius: 0px !important;
}

.crad_projet,
.crad_projet,
.crad_projet .card {
	border-radius: 15px !important;

}

.crad_projet .card-body {
	color: black;
	padding: 10px;
	background-color: #FAFAFA;
	border-bottom: 1px solid #e6e6e6;
	height: 120px;
}

.card-body h3 {
	text-align: left;
	font-size: 18px;
	font-family: 'Barlow Condensed', sans-serif;
	font-weight: bold;
	display: inline-block;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: 0 !important;
}

.card-body p {
	height: 45px;
	overflow: hidden;
}

.card-body_date {
	background-color: #FAFAFA;
	color: black;
	background-image: url(https://portfolio.3wwit.com/Projects/date_icon.svg);
	background-position: 15px 11px;
	background-repeat: no-repeat;
	padding: 10px 45px;
	border-bottom-left-radius: 15px !important;
	border-bottom-right-radius: 15px !important;
}

.card-body_date span {
	font-size: 14px;
	font-family: 'Barlow Condensed', sans-serif;
	font-weight: bold;
}

.projet_name {

	position: absolute;
	top: 0px;
	margin: 10px;

}

.name_categorie_projet {
	display: inline-block;
	text-align: center;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 15px;
	color: #000;
	padding: 5px;
	font-family: Barlow Condensed, sans-serif;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 5px;
	list-style-type: none;
}

.slick_block_title {
	display: none;
}

.block_title {
	margin-bottom: 20px !important;
	border-bottom: 0.1px solid #dedede !important;
}

/*
.crad_projet{
	cursor: pointer;
	width: 260px !important;
	background-repeat: no-repeat;
    background-size: 100% 166px;
}*/
.lien_slug_projet {
	background-color: #fafafa;
	color: black;
	margin-bottom: 16px;
	border-radius: 15px;
	display: block;
	height: 100%;
	width: 100%;
}

.lien_slug_projet:hover {
	color: black;
}

.card_block {
	margin-bottom: 16px;
}

.crad_image_head {
	height: 137px;
	border-top-left-radius: 18px !important;
	border-top-right-radius: 18px !important;
	background-color: #1dc7ea;
}

.card-body {
	padding: 15px;
	border-bottom: 1px solid #DEDEDE;
	background-color: #fafafa;
	height: 127px;
	width: 100%
}

.details .crad_projet {

	width: 300px;
}

.slick_block .card_block {
	margin-left: 1%;
}

.img_card_div {
	height: 166px;
	background-color: #1dc7ea;
	border-top-left-radius: 15px !important;
	border-top-right-radius: 15px !important;
}

.img_part {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.part_img_bloc {
	width: 49%;
	cursor: pointer;
}

.part_img_bloc_two {
	width: 100%;
	cursor: pointer;
}

.part_img_bloc_one {
	width: 100%;
	cursor: pointer;
}

#more_project_categorie {
	padding: 2% 6%;
}

.block_right {
	padding-left: 44px;
	float: right;
	padding-right: 0;
}

.block_right_img {
	float: right;
}

.part_projet_block {
	margin-bottom: 3%;
}

.img_part_projet {
	border-radius: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.description_sans_img {
	display: flex;
	justify-content: center;
	flex-direction: column;
	color: #000;
	font-family: Barlow Condensed, sans-serif;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	width: 100%
}

.part_content>* {
	word-wrap: break-word;
	color: #000;
	font-family: Barlow Condensed, sans-serif;
	font-size: 24px;
	font-weight: 400;
}

.container-fluid>.container {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.slick-next {
	right: 0 !important;
}

.prev_slide,
.next_slide {
	cursor: pointer !important;
}

.mySlides_image {
	position: relative;
	max-height: 100vh !important;
}

#top_project_modal {
	background: #fff;
	border-style: solid;
	box-sizing: border-box;
	margin: unset;
	max-width: 500px;
	padding: 15px 30px;
	position: relative;
	vertical-align: middle;
	width: 90%;
	z-index: 2;
}

.top_projects_row>div:first-child {
	padding-right: 0px;
	padding-left: 16px;
	margin-bottom: 16px;
}

.top_projects_row .right_top_projects {
	padding-left: 0px !important;
	padding-right: 0px;
	margin-bottom: 16px;
}

.right_top_projects div:first-child,
.right_top_projects div:nth-child(2) {
	margin-bottom: 8px;
}

.right_top_projects div:nth-child(3),
.right_top_projects div:nth-child(4) {
	margin-top: 8px;
}

.right_top_projects div:nth-child(2n),
.top_project_card.half_width {
	padding-right: 16px !important;
}

.right_top_projects div.top_project_card.half_width:nth-child(2n) {
	margin-top: 8px !important;
}

.right_top_projects .top_project_card {
	padding-left: 16px;
	padding-right: 0px;
}

.top_project_card {
	position: relative;
	height: 376px;
}

.top_project_card.half_height {
	height: 180px;
}

.top_project_card .top_project_img_container {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	position: relative;
}

.top_project_img_container .top_project_img_hover {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background: transparent linear-gradient(180deg, #D8D8D800 0%, #000000BF 100%) 0% 0% no-repeat padding-box;
	position: absolute;
	top: 0px;
}

.top_project_img_container .cover_top_project {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
}

.top_project_card .text_block {
	position: absolute;
	bottom: 0px;
	left: 31px;
}

.top_project_card .text_block span {
	font-weight: bold;
	font-size: 29px;
	letter-spacing: 0px;
	color: #FFFFFF;
}

.top_project_card .text_block p,
.text_block h3 {
	font-weight: normal;
	font-size: 17px;
	letter-spacing: 0px;
	color: #FFFFFF;
}

.shadow_filtre {
	visibility: hidden;
}

.bloc_back_to {
	margin-bottom: 10px;
}

.lien_back {

	font-family: "Barlow Condensed", sans-serif;
	background-image: url(https://portfolio.3wwit.com/Projects/left_f.png);
	background-repeat: no-repeat;
	background-size: 40px;
	background-position: left;
	font-size: 20px;
	padding-left: 40px;
	background-size: 40px;
	margin-bottom: 10px;
	cursor: pointer;
	color: rgb(0, 0, 0);
}

.image_slider {
	cursor: pointer !important;
	z-index: 999;
	box-shadow: 2px 1px #191919;
	height: 20px;
	background-color: #00000075;

}

@media only screen and (max-width: 991px) {

	.description_part_projet {
		width: 100%;
		padding-right: 15px;
	}

	.img_part {
		float: none !important;
		width: 100%;
	}

	.top_project_card .text_block span {
		font-size: 24px;
	}

	.top_project_card .text_block p {
		font-size: 14px;
	}

	.block_right {
		padding-left: 0;
		float: left;
	}

	.title_parts_details {
		font-size: 24px;
	}

	.part_content>* {
		overflow-wrap: break-word;
		font-size: 16px;
	}

	.titre_detai_in_img h1 {
		font-size: 25px !important;
	}

	.part_img_bloc {
		width: 100% !important;
	}
}

@media only screen and (max-width: 767px) {
	.crad_image_head {
		height: 400px;
	}
}

@media only screen and (max-width: 768px) {
	.top_projects_row>div:first-child {
		padding-left: 16px;
		padding-right: 16px !important;
	}

	#projList .crad_projet {
		width: 300px;
	}


}

@media only screen and (max-width: 535px) {

	.top_projects_row,
	.top_projects_row .right_top_projects {
		display: flex;
		flex-direction: column;
		align-items: center;
	}


	.top_projects_row>div:first-child,
	.right_top_projects .top_project_card {
		min-width: 260px !important;
		min-height: 180px !important;
		width: 80vw !important;
		height: 60vw !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.right_top_projects div:first-child,
	.right_top_projects div:nth-child(2) {
		margin-bottom: 16px;
	}

	.right_top_projects div:nth-child(3),
	.right_top_projects div:nth-child(4) {
		margin-top: 0px;
		margin-bottom: 16px;
	}

	.right_top_projects div:nth-child(2n) {
		padding-right: 0px !important;
	}
}

@media only screen and (max-width: 420px) {
	.details .crad_projet {
		width: 100% !important;
	}

	.lien_back {
		font-size: 18px;
		padding-left: 30px;
		background-size: 30px;
	}

}