
.block_Category .ui-switcher{
    background-color: #bdc1c2;
    border-radius: 10px;
    box-shadow: inset 1px 1px 1px rgb(0 0 0 / 15%);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 18px;
    margin: -2px 4px 0 0;
    position: relative;
    transition: border-color .25s;
    vertical-align: middle;
    width: 36px;
}
.block_Category .ui-switcher{
	float: right;
}
.ui-switcher[aria-checked=true]:after {
    left: 19px!important;
	content:""
}
.ui-switcher[aria-checked=false]:after {
  left: 2px;
}
.ui-switcher[aria-checked=false]:before {
  content: '';
  right: 7px;
}
.ui-switcher[aria-checked=true]:before {
  content: '';
  left: 7px;
}
.ui-switcher[aria-checked=true] {
    background-color: #3ec0f0!important;
}
.ui-switcher:after {
    background-color: #fff;
    border-radius: 50%;
    content: "\0020";
    display: inline-block;
    height: 15px;
    position: absolute;
	top: 1.5px;
    transition: left .25s;
    width: 16px;
	left: 2px;
}
.ui-switcher:before {
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 6px;
  height: 12px;
  width: 20px;
  text-align: center;
}