.gstc__chart-timeline-items-row-item {
	border-radius: 4px;
}
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: #ffffff80;
	text-align: center;
	padding-top: 20px;
}
.gstc__chart-timeline-items-row-item-label{
	margin:0px;
}
.gstc__chart-timeline-items-row-item-label,.gstc__chart-timeline-items-row-item-label a{
	display:flex;
	flex-grow: 1;
	flex-shrink: 0;
}
.gstc__chart-timeline-items-row-item-label a{
	padding: 4px 10px;
}
.task_calendar .item-description{
	font-size:11px;
	margin-top:2px;
	color:#fffffff0;
	line-height:1em;
}
.row-content-wrapper.project{
	display:flex
}

.row-content-wrapper.project .row-content{
	flex-grow:1;
}

.tool-shelf{
	 margin:20px; 
	text-align:center 
 } 
/* .tool-shelf .fc-left{ */
	/* float:left; */
/* } */
/* .tool-shelf .fc-right{ */
	/* float:right; */
/* } */
 .tool-shelf button.left_btn_shelf,.tool-shelf button#btn-prev-month{ 
	margin-left:20px;
 } 
.fc-button .fc-icon{
	position: relative;
    top: -0.05em;
    margin: 0 0.2em;
    vertical-align: middle;
}
.fc-button{
	border: 1px solid;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top,#fff,#e6e6e6);
    background-image: -webkit-gradient(linear,0 0,0 100%,from(#fff),to(#e6e6e6));
    background-image: -webkit-linear-gradient(top,#fff,#e6e6e6);
    background-image: -o-linear-gradient(top,#fff,#e6e6e6);
    background-image: linear-gradient(to bottom,#fff,#e6e6e6);
    background-repeat: repeat-x;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
    color: #333;
    text-shadow: 0 1px 1px rgb(255 255 255 / 75%);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
	box-sizing: border-box;
    margin: 0;
    height: 30px;
    padding: 0 0.6em;
    white-space: nowrap;
    cursor: pointer;
}
.fc-right .fc-button-group .fc-button{
	float: left;
    margin: 0 0 0 -1px;
	border-radius:0;
}
.fc-right .fc-button-group .btn_week{
	border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.fc-right .fc-button-group .btn_3_month{
	border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.fc-center{
	font-size: 36px;
}
.fc-state-active{
    background-color: #ccc;
    background-image: none;
    box-shadow: inset 0 2px 4px rgb(0 0 0 / 15%), 0 1px 2px rgb(0 0 0 / 5%);
}
.fc-today-button.disabled, .fc-button-group button[disabled]{
	cursor: not-allowed;
    pointer-events: none;
    background-image: none;
    opacity: .65;
    box-shadow: none;
    color: #333;
    background-color: #e6e6e6;
}
.fc-icon-left-single-arrow:after {
    content: "\2039";
    font-weight: 700;
    font-size: 200%;
    top: -7%;
}
.fc-icon-right-single-arrow:after {
    content: "\203A";
    font-weight: 700;
    font-size: 200%;
    top: -7%;
}
/* .tool-shelf button#btn-next-month,.tool-shelf button.right_btn_shelf{ */
	/* margin-right:20px; */
/* } */
.gstc__chart-timeline-items-row-item.green {
	background: green;
}
.slide_buttons .fc-button{
	width: 40px;
}
.slide_buttons{
	display: none;
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	z-index: 99999;
	background-color: #f7f7f8;
	padding: 0px;
}
.fc-left{
	margin-bottom: 5px;
}
@media(Max-width:991px) {  
	.fc-center h2{
       font-size: 25px;

	}
}

.loader_container {
    display: none;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.loader_container p {
    margin-right: 10px;
    margin-bottom: 0;
}

.fc-center h2 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: center;
}