.card.connect_page {
    max-width: 50%;
    min-width: 300px;
    margin: auto;
    margin-top: 20vh;
    position: relative;

}

.reduced-spacing {
    margin: 0;
    padding: 0;
}

.checkbox-label {
    margin-right: 10px;
}

.size_TextareaType {
    resize: none;
}

.project_color {
    color: #000;
}

.search_btn {
    margin-top: 26px !important;
    height: 40px;
}

.hidden-field {
    display: none;
}

.button_connect {
    margin: auto;
    display: table;
}

#nbcondidature {
    color: #000;
}

.header.conect {
    text-align: center;
}

.row_under_title_user_caregory_edit {
    margin-top: 2% !important;
}

body {
    background-color: white !important;
}

.table_width {
    margin-left: auto;
    margin-right: auto;
}

.right_container {
    float: right;
    display: flex;
    justify-content: space-around;
}

.left_container {
    float: left;
}

.custom_container {
    display: flex;
    align-items: center;
}

.sidebar[data-color=blue]:after {
    background: linear-gradient(180deg, #0c687f 0, #0c687f) !important;
}

.sidebar .nav {
    margin-top: 72px !important;
}

.select_top {
    width: unset !important;
    margin-right: 10px !important;
    margin-top: 3px !important;
}


.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}

.navbar-toggle {
    margin-top: 5px;
    margin-right: 0px;
    padding: 9px 0px;
}

.text_link {
    color: #333;
}

.formulaire {
    border: 0.2px solid #f1f1f1;
    border-radius: 15px;
    padding: 30px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.demander_acces {
    float: right;
}

.form_demander_acces {
    display: none;
}

.btn_retour {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
}

.btn_submit_form {
    display: flex;
    justify-content: center;
}

.souvenir_moi {
    float: left;
}

.error_mail_user {
    font-size: 14px;
    color: #D8000C;
    display: none;
}

#time_error {
    color: #D8000C;
}

.text_error {
    color: #D8000C;
}

#form_demander_acces_retour {
    float: right;
}

#activate_modal .modal-dialog {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: 90%;
    max-width: 600px;
}

#activate_modal .modal-content {
    width: 100%;
}

@media (max-width: 991px) {

    .nav-open .navbar-collapse,
    .nav-open .sidebar {
        z-index: 0;
    }

    .sidebar .nav-mobile-menu {
        display: none;
    }
}

.icone_list:before {
    font-size: 22px;
    color: #0c687f;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.project-parts-sans-description {
    max-width: 800px;
    margin: 0 auto;
}

.name_categorie_details {
    cursor: pointer;
}

.w-100 {
    width: 100% !important;
}

.icons_d_flex {
    display: flex !important;
}

.subtitel {
    margin-left: 15px;
    text-align: left;
    font-size: 16px;
}

.serach_field_2 .search_inner input {
    color: #000;
    font-size: 13px;
    height: 40px;
    width: 100%;
    border-radius: 19px;
    padding-left: 55px;
    border: 2px solid #0c687f;
    padding-right: 15px;
}

.serach_field_2 .search_inner button {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: transparent;
    border: 0;
    padding-left: 24px;
    padding-right: 11px;
    font-size: 15px;
    color: #0c687f;
}

.dataTables_filter label:has(input[type="search"]) {
    display: none;
}

.red_required {
    color: red;
}

.error_msg {
    color: red;
}

.only_error_msg {
    color: red;
}

.country_flag.fi {
    height: 1em;
}

.select2-container--default .select2-selection--single {
    height: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
}

/* #client_list_wrapper .dataTables_scroll .dataTables_scrollHead .dataTables_scrollHeadInner{
    width: 100% !important;
} */

.profile_info>i {
    font-size: 45px;
    color: #fff;
}

.profile_info .profile_info_iner {
    z-index: 1000;
    position: absolute;
    right: 12px;
    background-color: #fff;
    text-align: left;
    width: 200px;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    top: 77px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    box-shadow: 0 0 2px 2px #d1d0d2;
}

.profile_info .profile_info_iner::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    top: -10px;
    right: 15px;
    border-width: 0 11px 12px 11px;
    border-color: transparent transparent #0c687f transparent;
    z-index: 2;
}

.profile_info:hover .profile_info_iner {
    opacity: 1;
    visibility: visible;
    top: 68px;
}

.profile_info .profile_info_iner .profile_author_name {
    padding: 7px 25px;
    border-radius: 10px 15px 0px 0px;
    background-color: #0c687f;
}

.profile_info .profile_info_iner a {
    display: block;
}

.profile_info .profile_info_iner h5 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.profile_info .profile_info_iner p {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}

.profile_info .profile_info_details {
    padding: 4px 25px;
}

.profile_info .profile_info_details a {
    font-size: 14px;
    display: block;
    padding: 10px 0;
    font-weight: 500;
}

.profile_info .profile_info_details a,
.profile_info .profile_info_details a:hover {
    color: #0c687f;
}

.d-flex {
    display: flex !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-item-center {
    align-items: center !important;
}

#user_jour_feries_repetitif {
    margin-left: 10px;
}

#recrutement_candidat_tests_date_fin {
    pointer-events: none;
}

.modal-details_affectation .table-striped {
    width: 100% !important;
}

.modal-details_affectation .dataTables_scrollHeadInner {
    width: 100% !important;
}

.modal-details_affectation .modal-dialog {
    width: 80%;
}

.iconClose {
    position: absolute;
    right: 18px;
}

.wrapper:not(:has(>.sidebar))>.main-panel {
    width: 100%;
}

.sub-menu {
    list-style: none !important;
}

.float-end {
    float: inline-end;
}

.flex-end {
    justify-content: flex-end;
}

.relative {
    position: relative;
}

.pr-0 {
    padding-right: 0px;
}

.pb-15 {
    padding-bottom: 15px;
}


.custom_tabs .nav-tabs .nav-link {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    min-width: max-content;
    /* border-bottom: 2px solid #eaeaeb; */
    border-right: none;
    border-left: none;
}

.custom_tabs .nav-tabs .nav-link.active:hover {
    border-color: #eaeaeb #eaeaeb #ffffff #eaeaeb !important;
}

.custom_tabs .nav-link.active {
    border-color: #ffffff #ffffff #ffffff;
    background-color: unset;
    border-top: 1px solid #eaeaeb;
    border-right: 1px solid #eaeaeb;
    border-left: 1px solid #eaeaeb;
    border-bottom: 1px solid #fff;
    /* border-bottom: none; */
}

.custom_tabs .nav-tabs {
    border: none;
    width: fit-content;
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.custom_tabs .nav-tabs::-webkit-scrollbar {
    display: none;
}

.custom_tabs .nav-tabs .nav-link:hover span,
.custom_tabs .nav-link.active span {
    color: #0c687f;
    font-size: 16px;
    font-weight: 700;
}

.custom_tabs .nav-link span {
    margin: 0 20px;
    color: #eaeaeb;
    font-size: 16px;
    font-weight: 500;
}

.custom_tabs {
    margin-bottom: -1px !important;
    font-size: 20px;
}

.card.no-topBorder .header {
    padding: 40px 15px 0;
}

.row.titel-client {
    margin-left: 0px;
    margin-bottom: 30px;
}

.title2 {
    font-weight: 700;
    font-size: 18px;
    margin-left: 16px;
    margin-top: 25px;
    text-align: start;
}

.mt-0 {
    margin-top: 0px !important;
}

.ml-2 {
    margin-left: 20px;
}

.pt-0 {
    padding-top: 0px !important;
}

.icons_user {
    padding-right: 5px;
}

.display-none {
    display: none;
}

.div_header_demandes_clients {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.div_select_etat_demandes_clients {
    margin-bottom: 15px;
}

.affecter_devis {
    margin-bottom: 15px;
    display: none;
}

#ModalselectProjectDevis .body {
    margin: 50px 15px;
}

.notifcation>p {
    margin-left: 0px !important;
}

.notifcation {
    display: flex !important;
    align-items: center;
}

.notifcation span {
    display: flex;
    margin-left: 4px;
    background-color: #ff0000;
    padding: 0px 6px;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
}

.active .sub-menu-client,
.visible {
    display: block !important;
}

.containerTicket {
    font-family: "Trebuchet MS", sans-serif;
    display: flex;
    gap: 19px;
    padding: 5% 0;
}

.columnEtat {
    background: #ddd;
    min-height: 90vh !important;
    padding: 20px 0;
    border-radius: 10px;
}

.columnEtat h1 {
    text-align: center;
    font-size: 22px;
}

.item {
    background: #fff;
    margin: 20px 10px;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.droppable {
    border: 2px dashed #aaa;
    min-height: 50px;
    margin-bottom: 10px;
}

.hovered {
    background-color: #f0f0f0;
}

.user_list_content .button-margin {
    margin-top: 23px;
    margin-bottom: unset !important;
}

.align-items-center {
    align-items: center !important;
}

.libelle_livrable_style {
    padding: 0 4px;
}

.message_teste_state {
    font-weight: 400;
    font-size: 15px;
    margin-left: 30px;
}

.message_teste_state .candidat-name,
.message_teste_state .start-time {
    font-weight: 600;
}

.message_teste_state .end-time {
    color: #ff0000;
    font-weight: 600;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.remain-time {
    font-weight: 600;
}

.me-0 {
    margin-right: 0 !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.green_dot {
    cursor: pointer;
    display: block;
    border: unset;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
}

.red_dot {
    cursor: pointer;
    display: block;
    border: unset;
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 50%;
}

.orange_dot {
    cursor: pointer;
    display: block;
    border: unset;
    width: 15px;
    height: 15px;
    background: orange;
    border-radius: 50%;
}

#calendar_delete_item_modal .modalmsg {
    font-size: 22px;
    margin: 40px 0;
}

#calendar_delete_item_modal .modal-footer {
    border-top: none;
    padding: 0;
}

#Supp-modal .modal-body {
    border-style: solid;
}

#Supp-modal .modal-footer {
    border: unset;
    padding: 15px;
}

.modal_conge_msg {
    text-align: left;
}

.mt-dashbord {
    margin-top: 40px;
}

#recrutement_candidat_tests_test_type label {
    padding-left: 7px;
}

form[name="recrutement_candidat_tests"]:has(> div #recrutement_candidat_tests_test_type option[value="orale"]:checked) #test_field_container {
    display: none;
}

form[name="recrutement_candidat_tests"]:has(> div #recrutement_candidat_tests_test_type option[value="ecrit"]:checked) #test_field_container {
    display: block;
}

.erreur_text {
    text-align: left;
}

.div_user_jour_feries_date_hide,
.div_user_jour_feries_dates_hide {
    display: none;
}

#div_user_jour_feries_dates .col-md-6 {
    padding: 0px;
    padding-right: 15px;
}

.modal .flatpickr-wrapper:has(>#task_start_date),
.modal .flatpickr-wrapper:has(>#date_fin_previsionnel),
.modal .flatpickr-wrapper:has(>#date_fin_reel) {
    width: 100% !important;
}

.erreur_task {
    display: list-item;
    color: #d9534f;
}

.erreur_block {
    margin-bottom: 20px;
}

/* ----------------------------------breadcumb------------------------- */
.breadcrumb-nav {
    margin-right: 40px;
    margin-top: 35px;
}

.breadcrumb {
    justify-content: end;
    background-color: unset;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-family: Arial, sans-serif;
}

.breadcrumb-item {
    display: inline;
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
}

.breadcrumb-item a {
    color: #007bff;
    text-decoration: none;
}

.breadcrumb-item a:hover {
    text-decoration: underline;
}

.breadcrumb-separator {
    margin: 0 0.5rem;
    color: #6c757d;
    line-height: 0px;
    font-size: 20px;
}

.breadcrumb-separator::before,
.breadcrumb-item::before {
    content: unset !important;
}

.breadcrumb-item-active {
    color: #6c757d;
    font-weight: bold;
}

@media (max-width: 768px) {
    .breadcrumb {
        font-size: 12px;
    }
}

.main_header_titel {
    padding-left: 30px;
}

.pl-0 {
    padding-left: 0px !important;
}

.modal-subtitel {
    margin-bottom: 25px;
}

.date-inpute-modal label {
    font-size: 12px;
}

input.flatpickr-input {
    background-color: #FFF !important;
}

#heure_error {
    color: #d9534f;
}

#heure_error ul {
    list-style-type: "- ";
}

#info ul {
    list-style-type: "- ";
}

.erreur_titel {
    display: block;
    text-align: left;
    color: #d9534f;
    font-weight: 700;
    margin-left: -15px;
    margin-bottom: 5px;
}

.warning_titel {
    display: block;
    text-align: left;
    color: #dba401;
    font-weight: 700;
    margin-left: -15px;
    margin-bottom: 5px;
}

.warning_task {
    color: #dba401;
    display: list-item;
}

.warning_block {
    margin-bottom: 10px;
}

/* -----importe documents livrable--------------- */
#file-upload {
    display: none;
}

.document-livrable,
.document-livrable a {
    color: #333;
}

.delete-document-livrable {
    cursor: pointer;
}



.document-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 10px;
}



.item_click,
.item_click:hover {
    font-weight: 900;
    color: #d9534f;
}

.datepicker {
    z-index: 5555 !important;
}

.minImage {
    width: 100%;
}

.document-livrable a.delete-document-livrable i {
    font-weight: bold;
    font-size: 20px;
    color: red;
}

.erreur {
    color: #d9534f;
}

.document-livrable {
    position: relative;
    margin-bottom: 10px;
}

.document-livrable a.delete-document-livrable:has(i) {
    right: 15px;
    top: 0;
    position: absolute;
}

.document-livrable .name {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    height: 100%;
}

.file_icone {
    font-size: 1000%;
}

.document-livrable .document-name {
    text-align: center;
}

.livrable-titre {
    font-size: 11px;
}

.NameLivrable {
    display: flex;
}

.main-panel {
    margin-top: 10px;
}

#affectation_projet tbody tr {
    cursor: pointer;
}

.dataTables_scrollHead>div,
.dataTables_scrollHead>div>table,
.dataTables_scrollBody>table {
    width: 100% !important;
}

.filter-button {
    color: #1dc7ea;
    margin-right: 5px;
    border-radius: 40px;
    padding: 7px 20px;
    border: 1px solid #1dc7ea;
    background-color: #fff;
}

.filter-button.selected,
.filter-button:hover {
    color: #fff;
    background-color: #1dc7ea;
}

.filter-div {
    margin-bottom: 15px;
    margin-top: 15px;
    justify-content: center;
    width: fit-content;
    flex-wrap: wrap;
}

.mb-20 {
    margin-bottom: 20px;
}

.EditlivrableDocs {
    display: flex;
    width: fit-content;
    padding: 0;
    position: absolute;
    top: 1px;
    right: 40px;
}

.select2-container--default .select2-selection--multiple {
    min-height: 40px;
}