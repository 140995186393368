select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	outline: 0;
	background: #1dc7ea;
	background-image: none;
	border: 1px solid black;
}
 
.select {
	position: relative;
	display: block;
	width: 13em;
	height: 3em;
	line-height: 3;
	background: #2C3E50;
	overflow: hidden;
	border-radius: .25em;
}

.categorie_table_logo{
	object-fit: contain;
}

select {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0 0 0 .5em;
	color: #fff;
	cursor: pointer;
}
 
select::-ms-expand {
	display: none;
}
 
.select::after {
	content: '\25BC';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 0 1em;
	background: #34495E;
	pointer-events: none;
}
 
.select:hover::after {
	color: #F39C12;
}
 
.select::after {
	-webkit-transition: .25s all ease;
	-o-transition: .25s all ease;
	transition: .25s all ease;
}
.search_button{
	float:right;
	
}
.filtre_tags{
	float:left;
}
#select_categories_container{
	padding-top: 10px;
	padding-bottom: 10px;
}