.title {
    float: left;
}

.previsualiser_button {
    float: left;
    background-color: #1dc7ea !important;
    border: none;
    color: #ffffff !important;
    padding: 1% 1%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px 11px;
    cursor: pointer;
}

.cover_edit_project {
    display: none;
}

.radio_roles_part {
    display: none;
}

.p-2 {
    background-color: #3f474e;
    color: #fff;
    padding: 10px !important;
    margin: 0px;
    display: inline-block;
}

.sites_photos {
    height: 100px;
    display: block;
}

.image_box {
    padding-left: 29px;
    margin-left: 0px;
    margin-right: 0px;
}

.charger_plus {
    text-align: center;
}

#details_project .img_projects_all_div {
    height: 108px;
    width: 100%;
}

#myModal .closeModal {
    background: url('../../images/close_btn.png')no-repeat;
    background-size: 26px;
    width: 26px;
    height: 26px;
    z-index: 9999;
    cursor: pointer;
}

#myModal .w3-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

#myModal .prev_slide .image_slider,
#myModal .next_slide .image_slider {
    height: 20px;
}

#myModal .mySlides_image {
    max-width: 100%
}

#myModal .mySlides {
    max-width: 60%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

i.fa-long-arrow-down.uncheck {
    font-size: 20px;
    cursor: pointer;
    color: red;
}

i.fa-long-arrow-up.check {
    font-size: 20px;
    cursor: pointer;
    color: green;
}

.error_msg {
    color: red;
}

.DivInputExiste label {
    display: block;
}

.custom_div {
    margin-top: 30px;
}

.custom_pos {
    display: flex;
    align-items: center;
}

.height-92 {
    height: 92.15px;
}


.card .header.title-header {
    margin-bottom: 20px;
}

.project_page input[readonly],
.project_page select[readonly],
.project_page_Client_user .select2.select2-container,
.project_page_Client_user .richText>div,
.project_page_user .select2.select2-container,
.project_page_user .richText>div {
    pointer-events: none;
}

.project_page_Client_user .select2.select2-container,
.project_page_user .select2.select2-container {
    pointer-events: none;

}

.project_page_Client_user .select2.select2-container .select2-selection__rendered,
.project_page_user .select2.select2-container .select2-selection__rendered {
    background: #f5f5f5;
    height: 38px;
    color: #888888;
}

.row.titel-div {
    margin-left: -25px;
}

.message_ticket_div {
    margin-top: 20px;
}

.listheadTab {
    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style: none;
    font-size: 20px;
}

.listheadTab {
    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style: none;
    font-size: 20px;
    padding: 0px 20px;
    margin-bottom: 40px;


}

.tab {
    padding: 0px 20px;
}

.listheadTab>li {
    width: 50%;
    border-bottom: 1px solid;
    padding: 0px 15px;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;


}

.listheadTab>li a {
    color: #000000;
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listheadTab>li.active {
    border-top: 1px solid !important;
    border-left: 1px solid !important;
    border-right: 1px solid !important;
    border-bottom: 0px solid !important;

}

.custom_text_input {
    height: 250px !important;
}

.icon_file_fas span {
    text-align: left;
    width: 80%;
    line-height: 14px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom_label {
    margin-top: 20px;
    margin-left: 15px;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.delete_file_footer {
    cursor: pointer;
}

.custom_div_files {
    padding-top: 10px;
    margin-left: 30px;
    min-height: 20px;
    border: 1px solid;
    justify-content: right;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 60px);
    border-radius: 10px;
    border-color: #e3e3e3;
}

#modal-ajout-edite-message-div .row .custom_label .item__label {
    margin-left: 16px;
}

.project_page_Client_user .dataTables_scrollHeadInner,
.project_page_user .dataTables_scrollHeadInner {

    width: 100% !important;
}

.project_page_Client_user #information_generale form label,
.project_page_user #information_generale form label {
    width: 100% !important;
}

.hidden-field {
    display: none;
}



.message-container {
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.message-container.user-message {
    border-radius: 8px 8px 0px 8px;
}

.message-container.other-message {
    border-radius: 8px 8px 8px 0px;
}

.user-message {
    text-align: right;
    background-color: #3498db;
    color: #fff;
}

.other-message {
    text-align: left;
    background-color: #69d465;
    color: #fff;
}

.sender-name {
    font-size: 13px;
    font-weight: bold;
    color: #a4a4a4;
    background-color: unset;
}

.attachments-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.attachment-item {
    max-width: 30%;
    padding-left: 5px;
    margin-top: 5px;
    color: #3498db;
}

.attachment-item .icons_user {
    padding-right: 0px;
}

.attachment-item .file-name {
    width: 100%;
}

.message-div {
    background-color: unset;
    display: flex;
}

.message-div.user-message,
.message-div.user-message .attachments-list {
    justify-content: end;
}

.message-div.other-message,
.message-div.other-message .attachments-list {
    justify-content: start;
}

.titel-attachment-item {
    margin-bottom: 0px;
    color: #d1d1d1;
    font-size: 13px;
}

.message-block {
    position: relative;
    max-width: 50%;

}

.attachment-item .file-image {
    margin-bottom: 4px;
    justify-content: center;
    display: flex;
    width: 100%;
}

.separtor-titel-message {
    height: 1px;
    background-color: #aaaaaa;
    flex-grow: 1;
}

.messages-block-titel .subtitel {
    color: #aaaaaa;
    font-size: 14px;
    line-height: 14px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: -7px;

}

.messages-block-titel {
    margin: auto;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
}

.modal-ajout-edite-message {
    margin: auto;
    width: 80%;
}

.btn-div {
    display: flex;
    justify-content: center;
}

.btn-div button {
    margin-bottom: 20px;
}

@media (max-width: 721px) {
    .message-block {
        max-width: 90%;

    }
}

.message-main-div {
    margin-top: 34px;
}

#modal-ajout-edite-message-div .card {
    margin: 0;
}

#upload_files .close-modal {
    display: none;
}

.main-drop-div {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 14px;
}

#message-content {
    min-height: 150px;
}

.button_plus {
    height: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-block p.sender-name {
    justify-content: end;
    display: flex;
}

.message-block p.sender-name span i.pe-7s-more {
    rotate: 90deg;
    font-weight: bolder;
    visibility: hidden;
}

.user-message p.sender-name span:first-child {
    order: 2;
}

.user-message p.sender-name span:last-child {
    order: 1;
}

.message-block:hover p.sender-name span i.pe-7s-more {
    visibility: visible;
    cursor: pointer;
}

.message-option {
    z-index: 1;
    position: absolute;
    right: -5px;
    background-color: #fff;
    text-align: left;
    width: 200px;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    min-height: 50px;
    top: 16px;
    box-shadow: 0 0 3px 1px #d1d0d2;
}

.message-option-div {
    margin-bottom: 4px;
    margin-top: 4px;
    padding: 10px;
}

.message-option-div:hover a {
    background-color: #a4a4a4;
}

.message-option.show-dropdown,
.message-option.show-dropdown .message-option-div {
    opacity: 1;
    visibility: visible;
}

#upload_files.modal-ajout-edite-message {
    overflow: auto;
    scrollbar-width: none;
    max-height: 500px;
    overflow-x: clip;
}

#upload_files.modal-ajout-edite-message .richText .richText-editor {
    height: 150px !important;
}

.file_projet_liste {
    padding-bottom: 5px;
    display: flex;
    width: 50%;
}

.message-option-div:hover {
    background-color: ghostwhite;
}

.image_div {
    width: 100%;
}

select[readonly].select2-hidden-accessible+.select2-container {
    pointer-events: none;
    touch-action: none;
}

select[readonly].select2-hidden-accessible+.select2-container .select2-selection {
    background: #eee;
    box-shadow: none;
}

select[readonly].select2-hidden-accessible+.select2-container .select2-selection__arrow,
select[readonly].select2-hidden-accessible+.select2-container .select2-selection__clear {
    display: none;
}

.button_plus_color {
    color: #42d0ed !important;
}

.button_minus_color {
    color: #EE2D20 !important;
}

.containerTicket .ticket_project_livrable .ticket-libelle {
    font-weight: 700;
}

.ticket_project_livrable .ticket-info {
    justify-content: end;
    font-size: 12px;
}

.ticket_project_livrable .ticket-info .ticket-nbMessage {
    padding-left: 5px;
}

.ticket_project_livrable .ticket-dateDernierMessage {
    display: flex;
    justify-content: end;
    font-size: 12px;
}