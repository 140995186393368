.add_user_btn {
    background-color: #3f6791;
    box-shadow: none;
    color: #fff;
    float: right;
    width: fit-content;
}

.radio_roles {
    display: none;
}

.modalTeamDev {
    z-index: 100000;
}

.user_filter {
    border-radius: 25px;
}

.checked {
    background-color: #5bc0de !important;
    color: #fff !important;
    border: solid 1px #46b8da;
}

.licences_filter,
.user_filter {
    clear: both;
    overflow: hidden;
    display: table;
    margin: 20px auto;
    text-align: center;
    color: #fff;
}

.user_filter {
    cursor: pointer;
}

.licences_filter a,
.user_filter {
    padding: 12px 30px;
    font-size: 15px;
    text-transform: uppercase;
    margin: 3px 20px;
    display: inline-block;
    border: 1px solid #46b8da;
    border-radius: 25px;
    color: #5bc0de !important;
}

.user_filter.checked {
    background-color: #5bc0de !important;
    color: #fff !important;
    border: solid 1px #46b8da;
}

.style_role {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #9a9a9a;
    font-weight: 400;
}

.style_radio {
    max-width: 200px;
}

.modal_test {
    vertical-align: middle;
    z-index: 2;
    max-width: 500px;
    box-sizing: border-box;
    width: 90%;
    background: #fff;
    padding: 15px 30px;
    margin: unset;
    position: relative;
    border-style: solid;
}

.blocker {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #0303038c;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.href_style {
    color: inherit;
}

.custom_password_style>div {
    width: 49% !important;
}

.error_form_password {
    color: #ff0000;
}



.Customloader {
    display: none;
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #0c687f;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 40%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 6000 !important;
}

.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000 !important;
    opacity: 0.5 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 5000 !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#modal_edit_ticket .modal-dialog {
    width: 80% !important;
}

.errorMessage {
    color: #D8000C;
    border-radius: 5px;
    font-size: 14px;
    font-family: Arial, sans-serif;
}