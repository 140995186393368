#discution-div {
    overflow-y: auto;
    max-height: 400px;
    overflow-x: clip;
}

.image-preview {
    height: 150px;
    width: 150px;
    cursor: pointer;
}

/* ------------------messages------------ */

.message-ticket-input,
.message-ticket-input-edite {
    width: 100%;
    padding-left: 20px;
    padding-right: 50px;
    border: 1px solid #ddd;
    border-radius: 20px;
    box-sizing: border-box;
    font-size: 14px;
    outline: none;
    resize: none;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
}

.message-ticket-input::placeholder,
.message-ticket-input-edite::placeholder {
    color: #aaa;
}

.message-ticket-container {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    margin-top: 10px;
}

.ticket-attache-file {
    top: 4px;
    right: 85px;
    position: absolute;
    font-size: 29px;
    color: #000;
    cursor: pointer;
}

.ticket-attache-file:hover {
    color: #000;
}

.send-ticket-message-button,
.send-ticket-message-edite-button {
    width: 50px;
    border: unset;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 5px;

}

.send-ticket-message-button i,
.send-ticket-message-edite-button i {
    line-height: 28px;
    font-size: 23px;
}


#message_inpute_div .dropzone.dz-clickable {
    cursor: unset;
}

#message_inpute_div .dropzone {
    min-height: unset;
    border: unset;
    background: unset;
    padding: unset;
}

#message_inpute_div .dz-message {
    width: calc(100% - 134px);
    height: 46px;
    z-index: 2;
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

#message_inpute_div {
    position: relative;
}

.titel-modal-ticket {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
}

.modal-btn-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.ticket-footer-block {
    display: flex;
    justify-content: space-between;
}

.ticket-nbMessage {
    font-size: 12px;
}

.ticket-archive i {
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 30px;
    font-weight: 900;
    background: #ffff;
    border-radius: 45px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ticket-archive{
    position: relative;
}